/* eslint-disable import/prefer-default-export */
import { Container, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import React, { useEffect, useState, useMemo } from 'react';

export function AfterSubmitModal({ showFinalSpinner, handleCloseFinalSpinner }) {
  return (
    <Modal animation={false} fullscreen show={showFinalSpinner} onHide={handleCloseFinalSpinner}>
      <Spinner size="sm" animation="border" variant="info" />
    </Modal>
  );
}
