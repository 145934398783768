import loadable from '@loadable/component';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import firebase from 'firebase';
import { linkResolver } from '../utils/LinkResolver';

// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { defaultLanguage } from '../../prismic-configuration';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { AfterSubmitModal } from '../commons/AfterSubmitModal';

// @ts-ignore
const StyledFirebaseAuth = loadable(() => import('react-firebaseui/StyledFirebaseAuth'));

function SignUpTemplate({ data }) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [name, setName] = useState(''); // Local signed-in state.
  const [showFinalSpinner, setShowFinalSpinner] = useState(true);
  const [uiConfig, setUiConfig] = useState('');
  const isBrowser = typeof window !== 'undefined';

  // const user = firebase.auth().currentUser
  const handleCloseFinalSpinner = () => setShowFinalSpinner(false);
  const handleShowFinalSpinner = () => {
    setShowFinalSpinner(true);
    setTimeout(() => {
      setShowFinalSpinner(false);
      // alert(
      //   'Something went wrong, please contact us by email info@enterprof.org',
      // )
    }, 1000);
  };
  useEffect(() => {
    if (isBrowser) {
      const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
        tosUrl: `${langResolver}/privacy`,
        privacyPolicyUrl: `${langResolver}/privacy`,
        signInSuccessUrl: `${langResolver}/personal-page`,
        callbacks: {
          // Avoid redirects after sign-in.
          signInSuccessWithAuthResult: () => true,
        },
      };
      setUiConfig(uiConfig);
    }
  }, [name, isBrowser]);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    if (isBrowser) {
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
        setIsSignedIn(!!user);
        setName(user?.displayName);
      });
      return () => {
        unregisterAuthObserver();
      }; // Make sure we un-register Firebase observers when the component unmounts.
    }
  }, [name, isBrowser]);

  let user = '';
  let firebaseAuth = {};

  if (isBrowser) {
    user = firebase.auth().currentUser;
    firebaseAuth = firebase.auth();
  }

  if (!data) return null;

  const pageContent = data.prismicLoginAndSignUpPage;
  const doc = data.prismicLoginAndSignUpPage.data;
  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const topMenu = data.prismicMenuLink || {};
  const langResolver = lang === defaultLanguage ? '' : `/${lang}`;
  if (isSignedIn) {
    navigate(`${langResolver}/personal-page`);
  }

  return (
    <div className="d-flex justify-content-center flex-column w-100">
      <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
        <Seo title="Home" lang={lang} />
        <div style={{ minHeight: '365px' }}>
          <h1 className="news-title mx-auto">{doc.title.text} </h1>
          {doc.description?.text && <p className="m-3 mb-5 text-center">{doc.description?.text}</p>}
          <StyledFirebaseAuth
            uiCallback={() => {
              handleCloseFinalSpinner();
            }}
            uiConfig={uiConfig}
            firebaseAuth={firebaseAuth}
          />
        </div>
        <AfterSubmitModal
          showFinalSpinner={showFinalSpinner}
          handleCloseFinalSpinner={handleCloseFinalSpinner}
        />
      </Layout>
    </div>
  );
}

export const query = graphql`
  query prismicLoginAndSignUpPage($lang: String) {
    prismicLoginAndSignUpPage(lang: { eq: $lang }) {
      _previewable
      lang
      url
      type
      alternate_languages {
        uid
        type
        lang
      }
      data {
        title {
          raw
          text
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(SignUpTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
